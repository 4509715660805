import {Form, Link, redirect, useLoaderData, useParams, useRouteLoaderData} from "react-router-dom";
import RelationshipFormFields from "../components/relationship-form-fields";
import formDataTransformer from "../utils/form-data-transformer";
import * as React from "react";
import RelationshipKeyFormFields from "../components/relationship-key-form-fields";
import toMembersOption from "../utils/to-member-options";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export async function loader({ request }) {
    const url = new URL(request.url);
    const fromMemberId = url.searchParams.get('fromMemberId');
    const type = url.searchParams.get('type');

    return { fromMemberId, type };
}
export async function action({ request, params }) {
    const { genogramId } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));

    const {
        type,
        toMemberId,
        fromMemberId,
    } = data;

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/members/${fromMemberId}/relationships`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify(data)
        }
    );
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    return redirect(`/genograms/${genogramId}/members/${fromMemberId}/relationships/${toMemberId}/${type}`);
}

export default function RelationshipNew() {
    const { genogramId, memberId } = useParams();
    const { fromMemberId, type } = useLoaderData();
    const { genogram: { members } } = useRouteLoaderData('genogram');

    return (
        <Form className={'my-3'} method="post">
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} className={'btn btn-outline-secondary'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
                {memberId &&
                    <Link to={`/genograms/${genogramId}/members/${memberId}`}
                          className={'btn btn-outline-secondary me-auto ms-1'}>
                        <i className="bi bi-arrow-left"/>
                    </Link>
                }
                <button type={'submit'} className={'btn btn-success ms-auto'}>
                    <i className="bi bi-floppy"/>
                </button>
            </div>
            <h2 className={'my-2 text-center'}>Новая Связь</h2>
            <RelationshipKeyFormFields relationship={{fromMemberId, type}} members={toMembersOption(members)}/>
            <RelationshipFormFields relationship={{}} readOnly={false}/>
        </Form>
    )
}