import {Form, Link, redirect, useActionData, useParams, useRouteLoaderData} from "react-router-dom";
import MemberFormFields from "../components/member-form-fields";
import formDataTransformer from "../utils/form-data-transformer";
import * as React from "react";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";
import getValidationErrors from "../utils/get-validation-erros";

export async function action({ request, params }) {
    const { genogramId, memberId } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/members/${memberId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify(data)
        }
    );

    switch(response.status) {
        case 400:
            const responseBody = await response.json()
            return { errors: getValidationErrors(responseBody.reasons), data};
        case 500:
            const body = await response.text();
            return { errors: { global: [`Что-то пошле не так: ${body}`] }, data};
        default:
            return redirect(`/genograms/${genogramId}/members/${memberId}`);
    }
}

export default function MemberEdit() {
    const { member: originalData } = useRouteLoaderData('member') || {};
    const { genogramId } = useParams();
    const { errors, data } = useActionData() || [];

    const member = data || originalData;

    return (
        <Form className={'my-3'} method="post">
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} relative={'path'} className={'btn btn-outline-secondary me-1'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
                <Link to={'..'} relative={'path'} className={'btn btn-outline-secondary me-auto'}>
                    <i className="bi bi-arrow-left"/>
                </Link>
                <button type={'submit'} className={'btn btn-success'}>
                    <i className="bi bi-floppy"/>
                </button>
            </div>
            <h2 className={'my-2 text-center'}>Редактирование Учасника {member.firstName} {member.lastName}</h2>
            {errors?.global && errors.global.map((message, index) => (
                <div key={index} className={'alert alert-danger my-2 text-center'}>
                    {message}
                </div>
            ))}
            <MemberFormFields member={member} readOnly={false} errors={errors}/>
        </Form>
    )
}