import * as React from "react";
import relationship_labels from "../utils/relationship-type-labels";
import FormField from "./form-field";

export default function RelationshipKeyFormFields({relationship, members}) {

    return (
        <>
            <FormField member={relationship} name={'fromMemberId'} type={'select'} label={'Связь От'} options={members} placeholder={'Не Выбрано'} required={true}/>
            <FormField member={relationship} name={'type'} type={'select'} options={relationship_labels} label={'Тип Связи'} required={true}/>
            <FormField member={relationship} name={'toMemberId'} type={'select'} label={'Связь К'} options={members} placeholder={'Не Выбрано'} required={true}/>
        </>
    )
}
