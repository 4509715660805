import FormField from "./form-field";

export default function GroupField ({member, label, name, path = name, subFields, ...rest}) {
    return (
        <div className={'ps-2 border-3 border-start border-secondary-subtle mb-4'}>
            <label className={'form-label'}>{label}</label>
            {subFields.map(({name: subFieldName, path: subFieldPath = subFieldName, ...fi}, index) => (
                <FormField
                    {...rest}
                    {...fi}
                    key={index}
                    member={member}
                    name={name && [name, subFieldName].filter(Boolean).join('.')}
                    path={[path, subFieldPath].filter(Boolean).join('.')}
                />
            ))}
        </div>
    )
}