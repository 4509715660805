import {Form, Link, useRouteLoaderData} from "react-router-dom";

import * as React from "react";
import MemberSearchField from "../components/member-search-field";
import MemberRelationships from "../components/member-relationships";
import MemberKeyInfo from "../components/member-key-info";
import getRelationshipType from "../utils/get-relationship-type";

export default function GenogramView() {
    const { genogram: { members, genogramId, rootMember } } = useRouteLoaderData('genogram');

    return (
        <Form className={'my-3'}>
            <div className={'d-flex my-3'}>
                <Link to={`/genograms`} className={'btn btn-outline-secondary'}>
                    <i className="bi bi-house"/>
                </Link>
                <MemberSearchField genogramId={genogramId} inputProps={{className: 'form-control'}}
                                   className={'ms-1 flex-grow-1'}/>
                <Link to={`/genograms/${genogramId}/comments`} className={'btn btn-outline-secondary ms-1'}>
                    <i className={`bi bi-chat-dots`}/>
                </Link>
                <Link to={`/genograms/${genogramId}/paid-services`} className={'btn btn-outline-secondary ms-1'}>
                    <i className={`bi bi-cash`}/>
                </Link>
                <Link to={`/genograms/${genogramId}/export`} className={'btn btn-outline-secondary ms-1'}>
                    <i className={`bi bi-file-earmark-pdf`}/>
                </Link>
            </div>
            <h2 className={'my-2 text-center'}>Генограмма {rootMember.firstName} {rootMember.lastName}</h2>
            {members.map(member => (
                <div key={member.memberId} className={'py-1 my-2'}>
                    <Link
                        to={`/genograms/${member.genogramId}/members/${member.memberId}`}
                        className={'text-reset my-1 ps-1'}>
                        <MemberKeyInfo member={member}/>
                    </Link>
                    <MemberRelationships relationships={member.relationships?.filter(r => ['parent', 'child'].includes(getRelationshipType(r))) || []} emptyMessage={`Нет Родителей/Детей`}/>
                    <MemberRelationships relationships={member.relationships?.filter(r => !['parent', 'child'].includes(getRelationshipType(r))) || []} emptyMessage={`Нет Других Связей`}/>
                </div>
            ))}
        </Form>
    )
}