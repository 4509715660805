import {Outlet, Form, useParams, redirect, useNavigation} from "react-router-dom";
import {NavDropdown} from "react-bootstrap";

export async function action({ request }) {
    const formData = await request.formData();
    const { intent, genogramId, memberId } = Object.fromEntries(formData);

    const queryString = memberId ? `?fromMemberId=${memberId}` : '';

    switch (intent) {
        case 'create_genogram':
            return redirect(`/genograms/new`);
        case 'create_parent':
            return redirect(`/genograms/${genogramId}/members/new${queryString}&type=parent`);
        case 'create_child':
            return redirect(`/genograms/${genogramId}/members/new${queryString}&type=child`);
        case 'create_member':
            return redirect(`/genograms/${genogramId}/members/new${queryString}`);
        case 'create_relationship':
            return redirect(`/genograms/${genogramId}/relationships/new${queryString}`);
        case 'create_comment':
            return redirect(`/genograms/${genogramId}/comments/new`);
        case 'create_paid_service':
            return redirect(`/genograms/${genogramId}/paid-services/new`);
        case 'create_media':
            return redirect(`/genograms/${genogramId}/members/${memberId}/media/new`);
        default:
            throw new Error('Unexpected intent');
    }
}

export default function Root() {
    const { genogramId, memberId } = useParams();
    const { state } = useNavigation();

    return (
        <>
            <main className={'container pb-5'}>
                {(state === 'submitting' || state === 'loading') &&
                    <div className={"d-flex justify-content-center align-items-center vh-100"}>
                        <span className="spinner-border text-primary my-5"/>
                    </div>
                }
                { state === 'idle' && <Outlet/> }
            </main>
            <nav className={'navbar fixed-bottom navbar-expand-sm navbar-light bg-light'}>
            <div className="container-fluid justify-content-center">
                    <Form method="post">
                        <input type={'hidden'} name={'genogramId'} value={genogramId}/>
                        <input type={'hidden'} name={'memberId'} value={memberId || genogramId}/>
                        <NavDropdown title="Создать" id="navbarScrollingDropdown" align="end" className={'btn btn-primary btn-lg'}>
                            {!genogramId && !memberId && <NavDropdown.Item as='button' type="submit" name={'intent'} value={'create_genogram'}>Генограмму</NavDropdown.Item>}
                            {genogramId && <NavDropdown.Item as='button' type="submit" name={'intent'} value={'create_parent'}>Родителя</NavDropdown.Item>}
                            {genogramId && <NavDropdown.Item as='button' type="submit" name={'intent'} value={'create_child'}>Ребенка</NavDropdown.Item>}
                            {genogramId && <NavDropdown.Item as='button' type="submit" name={'intent'} value={'create_member'}>Учасника</NavDropdown.Item>}
                            {genogramId && <NavDropdown.Item as='button' type="submit" name={'intent'} value={'create_relationship'}>Связь</NavDropdown.Item>}
                            {genogramId && memberId && <NavDropdown.Item as='button' type="submit" name={'intent'} value={'create_media'}>Файл</NavDropdown.Item>}
                            {genogramId && <NavDropdown.Item as='button' type="submit" name={'intent'} value={'create_comment'}>Комментарий</NavDropdown.Item>}
                            {genogramId && <NavDropdown.Item as='button' type="submit" name={'intent'} value={'create_paid_service'}>Платную Услугу</NavDropdown.Item>}
                        </NavDropdown>
                    </Form>
                </div>
            </nav>
        </>
);
}