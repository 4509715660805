import {Form, Link, redirect, useRouteLoaderData} from "react-router-dom";
import * as React from "react";
import formDataTransformer from "../utils/form-data-transformer";
import PaidServiceFormFields from "../components/paid-service-fields";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export async function action({ request, params }) {
    const { genogramId } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));
    const { version, paidServiceId } = data;

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/paid-services/${paidServiceId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify({ version })
        }
    );
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    return redirect(`/genograms/${genogramId}/paid-services`);
}

export default function CommentsAll() {
    const { genogram: { genogramId, rootMember } } = useRouteLoaderData('genogram');
    const { paidServices } = useRouteLoaderData('paid-services');

    const onSubmit = (event) => {
        if (!window.confirm('Please confirm you want to delete this record.')) {
            event.preventDefault();
        }
    }

    return (
        <div>
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} className={'btn btn-outline-secondary me-auto'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
            </div>
            <h2 className={'my-2 text-center'}>Платные Услуги Генограммы {rootMember.firstName} {rootMember.lastName}</h2>
            {paidServices.length === 0 && <div className={'py-1 my-2'}>Нет платных услуг</div>}
            {paidServices.map(paidService => (
                <Form method="post" onSubmit={onSubmit} key={paidService.paidServiceId} className={'py-2 my-2 d-flex'}>
                    <div className={'flex-grow-1'}>
                        <PaidServiceFormFields paidService={paidService} readOnly={true}/>
                    </div>
                    <Link to={`${paidService.paidServiceId}/edit`} className="d-flex justify-content-center align-items-center ms-2">
                        <i className="bi bi-pencil text-secondary"/>
                    </Link>
                    <button type={"submit"} name={'paidServiceId'} value={paidService.paidServiceId}
                            className="d-flex btn justify-content-center align-items-center ms-2">
                        <i className="bi bi-trash text-danger"/>
                    </button>
                </Form>
            ))}
        </div>
    )
}