import React from 'react';
import {Form, Link, redirect} from "react-router-dom";
import formDataTransformer from "../utils/form-data-transformer";
import genders_labels from "../utils/gender-labels";
import FormField from "../components/form-field";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export async function action({ request }) {
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify(data)
        }
    );
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    const genogram = await response.json();

    return redirect(`/genograms/${genogram.genogramId}`);
}

export default function GenogramNew() {
    return (
        <Form className={'my-3'} method="post">
            <div className={'d-flex my-3'}>
                <Link to={`/genograms`} className={'btn btn-outline-secondary me-auto'}>
                    <i className="bi bi-house"/>
                </Link>
                <button type={'submit'} className={'btn btn-success'}>
                    <i className="bi bi-floppy"/>
                </button>
            </div>
            <h2 className={'my-2 text-center'}>Новая Генограмма</h2>
            <FormField member={{}} name={'firstName'} label={'Имя'} type={'text'}/>
            <FormField member={{}} name={'lastName'} label={'Фамилия'} type={'text'}/>
            <FormField member={{}} name={'gender'} label={'Пол'} type={'select'} options={genders_labels}/>
        </Form>
    )
}