import {Form, Link, redirect, useParams, useRouteLoaderData} from "react-router-dom";
import formDataTransformer from "../utils/form-data-transformer";
import * as React from "react";
import CommentFormFields from "../components/comment-fields";
import toMembersOption from "../utils/to-member-options";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";
export async function action({ request, params }) {
    const { genogramId, commentId } = params;
    const formData = await request.formData();
    const data = formDataTransformer(Object.fromEntries(formData));

    const response = await fetch(
        `${resolveApiEndpoint()}/api/genograms/${genogramId}/comments/${commentId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            mode: 'cors',
            body: JSON.stringify(data)
        }
    );
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    return redirect(`/genograms/${genogramId}/comments`);
}

export default function CommentEdit() {
    const { genogram: { genogramId, members } } = useRouteLoaderData('genogram');
    const { comments } = useRouteLoaderData('comments');
    const { commentId } = useParams();

    const comment = comments.find(c => c.commentId === commentId) || {};

    return (
        <Form className={'my-3'} method="post">
            <div className={'d-flex my-3'}>
                <Link to={`/genograms/${genogramId}`} relative={'path'} className={'btn btn-outline-secondary me-1'}>
                    <i className="bi bi-diagram-3"/>
                </Link>
                <Link to={`/genograms/${genogramId}/comments`} className={'btn btn-outline-secondary me-auto'}>
                    <i className="bi bi-arrow-left"/>
                </Link>
                <button type={'submit'} className={'btn btn-success'}>
                    <i className="bi bi-floppy"/>
                </button>
            </div>
            <h2 className={'my-2 text-center'}>Редактирование Комментария</h2>
            <CommentFormFields comment={comment} readOnly={false} members={toMembersOption(members)}/>
        </Form>
    )
}