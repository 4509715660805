import React, {useState} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import genders_labels from "../utils/gender-labels";

export default function SearchField({ placeholder = 'Поиск', urlBuilderFn, handlerFn, ...rest }) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleSearch = (query) => {
        setIsLoading(true);

        fetch(urlBuilderFn(query))
            .then((resp) => resp.json())
            .then(({ data }) => {
                setOptions(data);
                setIsLoading(false);
            });
    };

    const handleSelection = ([member]) => {
        if (member) {
            handlerFn(member)
        }
    }

    const filterBy = () => true;

    return (
        <AsyncTypeahead
            {...rest}
            id={'memberSearchField'}
            filterBy={filterBy}
            isLoading={isLoading}
            labelKey={member => `${member.firstName} ${member.lastName} (${genders_labels[member.gender]})`}
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder={placeholder}
            onChange={handleSelection}
        />
    );
}