import {useState} from "react";
import dig from "../utils/dig";
import GroupField from "./group-field";
import * as React from "react";

export default function MultiField ({pluralLabel, label, member, name, readOnly, subFields = [], ...rest}) {
    const collection = dig(member, name);
    const [data, setData] = useState([...collection || []]);

    const handleAdd = () => {
        setData([...data, {}]);
    }

    const handleRemove = (index) => {
        setData([...data].splice(index, 1));
    }

    return (
        <div className={'ps-2 border-3 border-start border-secondary-subtle my-4'}>
            <div className={'d-flex justify-content-between'}>
                <label className={'form-label'}>{pluralLabel}</label>
                {!readOnly &&
                    <span className="d-flex justify-content-center align-items-center ms-2" onClick={handleAdd}>
                        <i className="bi bi-plus-circle-fill text-success"/>
                    </span>
                }
            </div>
            {data.map((entry, index) => (
                <div key={index} className={'d-flex'}>
                    <div className={'flex-grow-1'}>
                        <GroupField
                            {...rest}
                            readOnly={readOnly}
                            label={[label, index + 1].join(' ')}
                            member={data}
                            name={[name, index].join('.')}
                            path={[index].join('.')}
                            subFields={subFields}
                        />
                    </div>
                    {!readOnly &&
                        <span className="d-flex justify-content-center align-items-center ms-2" onClick={() => handleRemove(index)}>
                            <i className="bi bi-trash text-danger"/>
                        </span>
                    }
                </div>
            ))}
        </div>
    )
}