import {Collapse} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import FormField from "./form-field";
import GroupField from "./group-field";
import MultiField from "./multi-field";
import genders_labels from "../utils/gender-labels";
import marker_labels from "../utils/marker-labels";
import MediaImage from "./media-image";

const mainFormFields = [
    {name: 'firstName', label: 'Имя', type: 'text', required: true},
    {name: 'lastName', label: 'Фамилия', type: 'text', required: true},
    {name: 'gender', label: 'Пол', type: 'select', options: genders_labels, required: true}
];

const extraFormFields = [
    {name: 'middleName', label: 'Отчество', type: 'text'},
    {name: 'birthDate', label: 'Дата Рождения', type: 'date'},
    {name: 'birthPlace', label: 'Место Рождения', type: 'text'},
    {name: 'deathDate', label: 'Дата Смерти', type: 'date'},
    {name: 'deathPlace', label: 'Место Сметри', type: 'text'},
    {name: 'burialPlace', label: 'Место Захоронения', type: 'text'},
    {name: 'nationality', label: 'Национальность', type: 'text'},
    {name: 'education', label: 'Образование', type: 'text'},
    {name: 'religion', label: 'Религия', type: 'text'},
    {name: 'hobby', label: 'Хобби', type: 'text'},
    {name: 'additionalInformation', label: 'Дополнитльная Информация', type: 'text'},
    {name: 'sources', label: 'Источники', type: 'text'},
];

const technicalFields = [
    {path: 'createdAt', label: 'Создано', type: 'text', readOnly: true},
    {path: 'updatedAt', label: 'Обновлено', type: 'text', readOnly: true},
]

const currentAddressFields = [
    {name: 'address', label: 'Адрес', type: 'text'},
    {name: 'country', label: 'Страна', type: 'text'},
    {name: 'comment', label: 'Комментарий', type: 'text'},
    {name: 'fromDate', label: 'Начиная С', type: 'date'},
];

const previousAddressFields = [
    {name: 'address', label: 'Адрес', type: 'text', required: true},
    {name: 'country', label: 'Страна', type: 'text'},
    {name: 'comment', label: 'Комментарий', type: 'text'},
    {name: 'fromDate', label: 'Начиная С', type: 'date', required: true},
    {name: 'toDate', label: 'До', type: 'date', required: true},
];

const currentJobFields = [
    {name: 'jobName', label: 'Название', type: 'text'},
    {name: 'title', label: 'Должность', type: 'text'},
    {name: 'comment', label: 'Комментарий', type: 'text'},
    {name: 'fromDate', label: 'Начиная С', type: 'date'},
];

const previousJobFields = [
    {name: 'jobName', label: 'Название', type: 'text', required: true},
    {name: 'title', label: 'Должность', type: 'text', required: true},
    {name: 'comment', label: 'Комментарий', type: 'text'},
    {name: 'fromDate', label: 'Начиная С', type: 'date'},
    {name: 'toDate', label: 'До', type: 'date'},
];

const awardsFields = [
    {name: 'award', label: 'Награда', type: 'text', required: true},
    {name: 'comment', label: 'Комментарий', type: 'text'},
    {name: 'receivedDate', label: 'Получена', type: 'date'},
];

const warsFields = [
    {name: 'war', label: 'Война', type: 'text', required: true},
    {name: 'comment', label: 'Комментарий', type: 'text'},
    {name: 'fromDate', label: 'Начиная С', type: 'date'},
    {name: 'toDate', label: 'До', type: 'date'},
];

const illnessesFields = [
    {name: 'illness', label: 'Болезнь', type: 'text', required: true},
    {name: 'comment', label: 'Комментарий', type: 'text'},
    {name: 'fromDate', label: 'Начиная С', type: 'date'},
    {name: 'toDate', label: 'До', type: 'date'},
];

const markerFields = [
    {name: 'marker', label: 'Маркер', type: 'select', options: marker_labels, required: true},
    {name: 'comment', label: 'Комментарий', type: 'text'},
    {name: 'fromDate', label: 'Начиная С', type: 'date'},
    {name: 'toDate', label: 'До', type: 'date'},
];

const eventsFields = [
    {name: 'event', label: 'Название', type: 'text', required: true},
    {name: 'comment', label: 'Комментарий', type: 'text'},
    {name: 'fromDate', label: 'Начиная С', type: 'date'},
    {name: 'toDate', label: 'До', type: 'date'},
]

export default function MemberFormFields({member, readOnly = true, errors = {}}) {

    const [showExtraFields, setShowExtraFields] = useState(Object.entries(errors).length > 0);
    const showExtraFieldsOnClick = (e) => {
        e.preventDefault();
        setShowExtraFields(!showExtraFields);
    };

    return (
        <>
            <input type={'hidden'} name={'version'} value={member?.version}/>
            {mainFormFields.map((fieldInfo, index) => (
                <FormField key={index} member={member} readOnly={readOnly} {...fieldInfo} errors={errors}/>
            ))}
            <a href="#" className="d-block text-center mb-3" onClick={showExtraFieldsOnClick}>
                {showExtraFields ? 'Спрятать' : 'Показать'} Дополнительные Данные
            </a>

            <Collapse in={showExtraFields}>
                <div>
                    {extraFormFields.map((fieldInfo, index) => (
                        <FormField key={index} member={member} readOnly={readOnly} {...fieldInfo} errors={errors}/>
                    ))}
                    <MultiField member={member} pluralLabel={'Маркеры'} label={'Маркер'}
                                readOnly={readOnly} name={'markers'} subFields={markerFields} errors={errors}/>

                    <GroupField member={member} label={'Текущий Адрес'} readOnly={readOnly} name={'currentAddress'}
                                subFields={currentAddressFields} errors={errors}/>
                    <MultiField member={member} pluralLabel={'Прежние Адреса'} label={'Прежний Адрес'}
                                readOnly={readOnly} name={'previousAddresses'} subFields={previousAddressFields} errors={errors}/>

                    <GroupField member={member} label={'Текущая Работа'} readOnly={readOnly} name={'currentJob'}
                                subFields={currentJobFields} errors={errors}/>
                    <MultiField member={member} pluralLabel={'Прежние Работы'} label={'Прежняя Работа'}
                                readOnly={readOnly} name={'previousJobs'} subFields={previousJobFields} errors={errors}/>

                    <MultiField member={member} pluralLabel={'Награды'} label={'Награда'} readOnly={readOnly}
                                name={'awards'} subFields={awardsFields} errors={errors}/>
                    <MultiField member={member} pluralLabel={'Войны'} label={'Война'} readOnly={readOnly} name={'wars'}
                                subFields={warsFields} errors={errors}/>
                    <MultiField member={member} pluralLabel={'Болезни'} label={'Болезнь'} readOnly={readOnly}
                                name={'illnesses'} subFields={illnessesFields} errors={errors}/>
                    <MultiField member={member} pluralLabel={'Важные События'} label={'Важное событие'} readOnly={readOnly}
                                name={'events'} subFields={eventsFields} errors={errors}/>

                    {readOnly && technicalFields.map((fieldInfo, index) => (
                        <FormField key={index} member={member} readOnly={readOnly} {...fieldInfo} errors={errors}/>
                    ))}

                    {member.media?.length > 0 && member.media.map(({ genogramMediaAccessUrl }) => (
                        <MediaImage mediaUrl={genogramMediaAccessUrl} containerClass={'my-2'}/>
                    ))}

                    {showExtraFields &&
                        <a href="#" className="d-block text-center mb-3" onClick={showExtraFieldsOnClick}>
                            Спрятать Дополнительные Данные
                        </a>
                    }
                </div>
            </Collapse>
        </>
    )
}
