const gender_labels = {
    'physical_or_mental_illness': 'Физическое или психическое заболевание',
    'alcohol_or_drug_abuse': 'Злоупотребление алкоголем или наркотиками',
    'suspected_alcohol_or_drug_abuse': 'Подозрение на злоупотребление алкоголем или наркотиками',
    'in_recovery_from_physical_or_mental_illness': 'В процессе восстановления после физического или психического заболевания',
    'in_recovery_from_alcohol_or_drug_abuse': 'В процессе восстановления после злоупотребления алкоголем или наркотиками',
    'gambling_addiction': 'Игровая зависимость',
    'depression': 'Депрессия',
    'obesity': 'Ожирение',
    'cancer': 'Рак',
    'heart_disease': 'Болезни сердца',
    'high_blood_pressure': 'Гипертония',
    'HIV_AIDS': 'ВИЧ / СПИД',
    'sexually_transmitted_diseases': 'Заболевания, передающиеся половым путем',
    'hepatitis': 'Гепатит',
    'diabetes': 'Диабет',
    'arthritis': 'Артрит',
    'autism': 'Аутизм',
    'alzheimers_disease': 'Болезнь Альцгеймера',
};

export default gender_labels;