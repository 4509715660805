const relationships_labels = {
    'parent': 'Родитель',
    'child': 'Ребенок',
    // Family
    'step_parent': 'Отчим/Мачеха',
    'sibling': 'Брат/Сестра',
    'step_sibling': 'Сводный брат/Сводная сестра',
    'marriage': 'Брак',
    'engagement': 'Помолвка',
    'legal_cohabitation': 'Законное Сожительство',
    'cohabitation': 'Сожительство',
    'dating': 'Отношения',
    'separation_in_fact': 'Разрыв',
    'legal_separation': 'Законной Разрыв',
    'divorce': 'Развод',
    'love_affair': 'Роман',
    // Emotional
    'apathetic': 'Безразличие',
    'harmony': 'Гармония',
    'hostile': 'Вражда',
    'violence': 'Насилие',
    'abuse': 'Злоупотрибление (к)',
    'abuse_by': 'Злоупотрибление (от)',
    'manipulative': 'Манипуляция (над)',
    'manipulated_by': 'Манипуляция (от)',
    'distant': 'Отдаление',
    'friendship': 'Дружба',
    'controlling': 'Контроль (над)',
    'controlled_by': 'Контроль (от)',
    'cutoff': 'Отсечение',
    'jealous': 'Зависть (к)',
    'jealousy_from': 'Зависть (от)',
    'love': 'Любовь',
    'conflict': 'Конфликт',
    'hate': 'Ненависть',
    'normal': 'Нормальние отношения',
    'other': 'Другое',
};

const inbound_relationships = ['child', 'abuse_by', 'manipulated_by', 'controlled_by', 'jealousy_from']

const outbound_relationship_labels = Object
    .entries(relationships_labels)
    .filter(([key]) => !inbound_relationships.includes(key))
    .reduce((a, [k, v]) => ({...a, [k]: v}), {});

export default relationships_labels;
export { outbound_relationship_labels };