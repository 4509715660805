export default function transform (formDataObject) {
    return Object.entries(formDataObject).reduce(
        (agg, [key, value]) => {

            if(!value || value === '') return agg;

            const [root, ...parts] = key.split('.');

            if(parts.length === 0){
                agg[root] = value
            } else if (parts.length === 1) {
                const collection = agg[root] || (isNaN(Number(parts[0])) ? {} : []);
                collection[parts[0]] = value;
                agg[root] = collection;
            } else {
                const collection = agg[root] || [];
                const subobject = collection[parts[0]] || {};
                subobject[parts[1]] = value;
                collection[parts[0]] = subobject;
                agg[root] = collection;
            }

            return agg;
        },
        {}
    )
}