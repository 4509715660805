import * as React from "react";
import { Link } from "react-router-dom";
import relationships_labels from "../utils/relationship-type-labels";
import MemberKeyInfo from "./member-key-info";

export default function MemberRelationships({relationships, emptyMessage = 'Нет связей'}) {
    return (
        <div className={`border-3 border-start border-secondary-subtle`}>
            {(!relationships || relationships.length === 0) && <div className={'p-1 my-2 d-flex justify-content-center'}>{emptyMessage}</div>}
            {relationships && relationships.length > 0 && relationships.map((r, index) => (
                <div className={`py-1 my-2 d-flex justify-content-evenly`} key={index}>
                    <i className={`bi bi-arrow-${r.direction === 'inbound' ? 'left' : 'right'} d-flex align-items-center text-secondary-emphasis mx-1`}/>
                    <hr className={'w-25 border-top border-secondary-subtle border-3'}/>
                    <Link
                        to={`/genograms/${r.genogramId}/members/${r.fromMemberId}/relationships/${r.toMemberId}/${r.fromType}`}
                        relative={'path'}
                        className={'text-reset my-1 ps-1'}>
                        {relationships_labels[getType(r)] || getType(r)}
                    </Link>
                    <hr className={'flex-grow-1 border-top border-secondary-subtle border-3'}/>
                    <Link
                        to={`/genograms/${r.genogramId}/members/${getMemberId(r)}`}
                        relative={'path'}
                        className={'text-reset my-1 ps-1'}>
                        <MemberKeyInfo member={r}/>
                    </Link>
                </div>
            ))}
        </div>
    )
}

const getMemberId = (r) => r.direction === 'outbound' ? r.toMemberId : r.fromMemberId;
const getType = (r) => r.direction === 'outbound' ? r.fromType : r.toType;