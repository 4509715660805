import dig from "../utils/dig";

export default function FormField ({member, name, type, path = name, label, readOnly, options, placeholder, required, errors = {}}) {

    const fieldErrors = (errors[name] || []).map(({ message, type }) => message || type);
    const extraClassNames = [];

    if(fieldErrors.length > 0) extraClassNames.push('is-invalid');

    return type === 'select' && readOnly !== true ? (
        <div className={'form-floating mb-2'}>
            <select className={`form-select ${extraClassNames.join(' ')}`} required={required}
                    name={name} {...({[readOnly ? 'value' : 'defaultValue']: dig(member, path)})}>
                {placeholder && <option value={''}>{placeholder}</option>}
                {Object.entries(options).map(([optionValue, optionLabel]) => (
                    <option key={optionValue} value={optionValue}>{optionLabel}</option>
                ))}
            </select>
            <label>{label}</label>
            {fieldErrors.map((message, index) => (
                <div key={index} className={'invalid-feedback'}>
                    {message}
                </div>
            ))}
        </div>
    ) : (
        <div className={'form-floating mb-2'}>
            <input type={type === 'select' ? 'text' : type} name={name} readOnly={readOnly} required={required}
                   className={`form-control ${extraClassNames.join(' ')}`} {...({[readOnly ? 'value' : 'defaultValue']: type === 'select' ? options[dig(member, path)] : dig(member, path)})} />
            <label>{label}</label>
            {fieldErrors.map((message, index) => (
                <div key={index} className={'invalid-feedback'}>
                    {message}
                </div>
            ))}
        </div>
    )
}