import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import Root, { action as rootAction} from './routes/root';
import Genograms, {loader as genogramsLoader} from './routes/genograms';
import Genogram, {loader as genogramLoader, shouldRevalidate as genograShouldReinvalidate} from './routes/genogram';
import GenogramView from './routes/genogram-view';
import GenogramNew, {action as genogramCreateAction} from './routes/genogram-new';
import Member, {loader as memberLoader, shouldRevalidate as memberShouldReinvalidate} from "./routes/member";
import MemberView, {action as memberDeleteAction} from "./routes/member-view";
import MemberEdit, {action as memberEditAction} from "./routes/member-edit";
import MemberNew, {loader as memberCreateLoader, action as memberCreateAction} from "./routes/member-new";
import Relationship , {loader as relationshipLoader} from "./routes/relationship";
import RelationshipView , {action as relationshipDeleteAction} from "./routes/relationship-view";
import RelationshipEdit , {action as relationshipEditAction} from "./routes/relationship-edit";
import RelationshipNew , {loader as relationshipCreateLoader, action as relationshipCreateAction} from "./routes/relationship-new";
import Comments , {loader as commentsLoader} from "./routes/comments";
import CommentsAll , {action as commentsAllAction} from "./routes/comments-all";
import CommentNew , {action as commentsCreateAction} from "./routes/comment-new";
import CommentEdit , {action as commentEditAction} from "./routes/comment-edit";
import PaidServices , {loader as paidServicesLoader} from "./routes/paid-services";
import PaidServicesAll , {action as paidServicesAllAction} from "./routes/paid-services-all";
import PaidServiceNew , {action as paidServiceCreateAction} from "./routes/paid-service-new";
import PaidServiceEdit , {action as paidServiceEditAction} from "./routes/paid-service-edit";
import Export, {action as exportAction} from "./routes/export";
import MediaNew, {action as mediaNewAction} from "./routes/media-new";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        action: rootAction,
        children: [
            {
                index: true,
                element: <Genograms/>,
                loader: genogramsLoader
            },
            {
                path: "genograms",
                element: <Genograms/>,
                loader: genogramsLoader
            },
            {
                path: "genograms/new",
                element: <GenogramNew/>,
                action: genogramCreateAction
            },
            {
                path: "genograms/:genogramId",
                element: <Genogram/>,
                loader: genogramLoader,
                shouldRevalidate: genograShouldReinvalidate,
                id: 'genogram',
                children: [
                    {
                        index: true,
                        element: <GenogramView/>,
                    },
                    {
                        path: "members/new",
                        element: <MemberNew/>,
                        action: memberCreateAction,
                        loader: memberCreateLoader
                    },
                    {
                        path: "relationships/new",
                        element: <RelationshipNew/>,
                        action: relationshipCreateAction,
                        loader: relationshipCreateLoader
                    },
                    {
                        path: "comments",
                        element: <Comments/>,
                        loader: commentsLoader,
                        id: 'comments',
                        children: [
                            {
                                index: true,
                                element: <CommentsAll/>,
                                action: commentsAllAction
                            },
                            {
                                path: ":commentId/edit",
                                element: <CommentEdit/>,
                                action: commentEditAction
                            }
                        ]
                    },
                    {
                        path: "comments/new",
                        element: <CommentNew/>,
                        action: commentsCreateAction
                    },
                    {
                        path: "paid-services",
                        element: <PaidServices/>,
                        loader: paidServicesLoader,
                        id: 'paid-services',
                        children: [
                            {
                                index: true,
                                element: <PaidServicesAll/>,
                                action: paidServicesAllAction
                            },
                            {
                                path: ":paidServiceId/edit",
                                element: <PaidServiceEdit/>,
                                action: paidServiceEditAction
                            }
                        ]
                    },
                    {
                        path: "paid-services/new",
                        element: <PaidServiceNew/>,
                        action: paidServiceCreateAction
                    },
                    {
                        path: "export",
                        element: <Export/>,
                        action: exportAction
                    }
                ]
            },
            {
                path: "genograms/:genogramId/members/:memberId",
                element: <Member/>,
                loader: memberLoader,
                shouldRevalidate: memberShouldReinvalidate,
                id: 'member',
                children: [
                    {
                        index: true,
                        element: <MemberView/>,
                        action: memberDeleteAction
                    },
                    {
                        path: "edit",
                        element: <MemberEdit/>,
                        action: memberEditAction
                    },
                    {
                        path: "media/new",
                        element: <MediaNew/>,
                        action: mediaNewAction
                    }
                ]
            },
            {
                path: "genograms/:genogramId/members/:fromMemberId/relationships/:toMemberId/:type",
                element: <Relationship/>,
                id: 'relationship',
                loader: relationshipLoader,
                children: [
                    {
                        index: true,
                        element: <RelationshipView/>,
                        action: relationshipDeleteAction
                    },
                    {
                        path: "edit",
                        element: <RelationshipEdit/>,
                        action: relationshipEditAction
                    }
                ]
            },

        ]
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);