import React from 'react';
import {useNavigate} from "react-router-dom";
import SearchField from "./search-field";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export default function MemberSearchField({ genogramId, ...rest }) {
    const navigate = useNavigate();
    return (
        <SearchField
            {...rest}
            placeholder={'Поиск Учасников'}
            urlBuilderFn={(query) => `${resolveApiEndpoint()}/api/genograms/${genogramId}/members?filter=${query}`}
            handlerFn={(member) => navigate(`/genograms/${genogramId}/members/${member.memberId}`)}
        />
    )
}