import FormField from "./form-field";
import * as React from "react";

export default function PaidServiceFormFields({paidService, readOnly = true}) {

    return (
        <>
            <input type={'hidden'} name={'version'} value={paidService?.version}/>
            {[
                {name: 'paidService', type: 'text', label: 'Услуга', required: true},
                {name: 'comment', type: 'text', label: 'Комментарий', required: true}
            ].map(fieldInfo => (
                <FormField member={paidService} readOnly={readOnly} {...fieldInfo}/>
            ))}
        </>
    )
}
