import {Form, Link, useLoaderData, useParams} from "react-router-dom";
import * as React from "react";
import MemberKeyInfo from "../components/member-key-info";
import GenogramSearchField from "../components/genogram-search-field";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export async function loader() {
    const response = await fetch(`${resolveApiEndpoint()}/api/genograms`);
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }
    const { data: genograms } = await response.json();
    return { genograms };
}
export default function Genograms() {
    const { genograms } = useLoaderData();

    return (
        <Form className={'my-3'}>
            <div className={'d-flex my-3'}>
                <GenogramSearchField inputProps={{className: 'form-control'}}
                                   className={'ms-1 flex-grow-1'}/>
            </div>
            <h2 className={'my-2 text-center'}>Все Генограммы</h2>
            {(!genograms || genograms.length === 0) && <div className={'py-1 my-2'}>Нет генограмм</div>}
            {genograms && genograms.length > 0 && genograms.map(genogram => (
                <div key={genogram.genogramId} className={'py-1 my-2 d-flex'}>
                    <Link
                        to={`/genograms/${genogram.genogramId}`}
                        className={'text-reset my-1 ps-1 me-auto'}>
                        <MemberKeyInfo member={genogram}/>
                    </Link>
                    <Link
                        to={`/genograms/${genogram.genogramId}/comments`}
                        className={'text-reset text-decoration-none my-1 px-2'}>
                        <i className={`bi bi-chat-dots`}/>
                    </Link>
                    <Link
                        to={`/genograms/${genogram.genogramId}/paid-services`}
                        className={'text-reset text-decoration-none my-1 px-2'}>
                        <i className={`bi bi-cash`}/>
                    </Link>
                    <Link
                        to={`/genograms/${genogram.genogramId}/export`}
                        className={'text-reset text-decoration-none my-1 px-2'}>
                        <i className={`bi bi-file-earmark-pdf`}/>
                    </Link>
                </div>
            ))}
        </Form>
    )
}