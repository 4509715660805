import React from 'react';
import {useNavigate} from "react-router-dom";
import SearchField from "./search-field";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export default function GenogramSearchField({ ...rest }) {
    const navigate = useNavigate();
    return (
        <SearchField
            {...rest}
            placeholder={'Поиск Генограм'}
            urlBuilderFn={(query) => `${resolveApiEndpoint()}/api/genograms?filter=${query}`}
            handlerFn={(member) => navigate(`/genograms/${member.genogramId}`)}
        />
    )
}