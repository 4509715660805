import {Outlet} from "react-router-dom";
import * as React from "react";
import resolveApiEndpoint from "../utils/api-endpoint-resolver";

export async function loader({params}) {
    const { genogramId } = params;
    const response = await fetch(`${resolveApiEndpoint()}/api/genograms/${genogramId}`);
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }
    const genogram = await response.json();
    return { genogram };
}

export function shouldRevalidate({ currentUrl, nextUrl }) {
    return currentUrl.pathname.split("/")[1] !== nextUrl.pathname.split("/")[1];
}

export default function Genogram() {
    return (
        <Outlet/>
    )
}