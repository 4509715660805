import * as React from "react";
import FormField from "./form-field";

const mainFormFields = [
    {name: 'genogramId', type: 'hidden'},
    {name: 'version', type: 'hidden'},
    {path: 'createdAt', label: 'Создано', type: 'text', readOnly: true},
    {path: 'updatedAt', label: 'Обновлено', type: 'text', readOnly: true},
];

export default function CommonTechnicalFormFields({entity, readOnly = true}) {

    return (
        <>
            {mainFormFields.map(fieldInfo => (
                <FormField member={entity} readOnly={readOnly} {...fieldInfo}/>
            ))}
        </>
    )
}
