import FormField from "./form-field";
import * as React from "react";

const mainFormFields = [
    {name: 'comment', label: 'Комментарий', type: 'text'},
    {name: 'fromDate', label: 'Начиная С', type: 'date'},
    {name: 'toDate', label: 'До', type: 'date'},
];

export default function RelationshipFormFields({relationship, readOnly = true}) {

    return (
        <>
            <input type={'hidden'} name={'version'} value={relationship?.version}/>
            {mainFormFields.map(fieldInfo => (
                <FormField member={relationship} readOnly={readOnly} {...fieldInfo}/>
            ))}
        </>
    )
}
